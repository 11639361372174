
const tarifMixin = {
    data() {
        return {
            showTarifModal: false,
            tarifForm: {
                comment: '',
                tarif: ''
            },
        };
    },
    methods: {
        openTarifModal(contactType) {
            this.showTarifModal = true;
        },
        resetTarifModal() {
            this.showTarifModal = false;
            this.$v.tarifForm.$reset();
            this.tarifForm = {
                comment: '',
                tarif: ''
            };
        },

    }
};

export default tarifMixin;