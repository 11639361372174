<template>
  <div v-if="app.number && app.status === 'ACCEPTED' || app.status === 'CONFIRMED'" class="mt-1">
    <button v-if="app.request_status !== 'TARIF_ACCEPTED'" :disabled="!app.canRequestInvoice" type="button" class="btn btn-primary btn-xs" style="width: 220px;text-align: left;padding:10px 15px" @click="requestInvoice">
      {{ getTranslation(invoiceRequestMsg) }}
    </button>
    <button v-if="app.request_status !== 'TARIF_ACCEPTED' && app.request_status !== 'INVOICE'" :disabled="!app.canRequestTarif" type="button" class="btn btn-xs btn-info mt-1"
            style="width: 220px;text-align: left;padding:10px 15px"
            :title="getTranslation('opportunity to reduce rates')"
            @click="openTarifModal">
      {{ getTranslation(tarifRequestMsg) }}
    </button>


    <PortalModal
        v-model="showTarifModal"
        :modal-title="getTranslation('requestTarif')"
        :ok-button-label="getTranslation('requestTarif')"
        :cancel-button-label="getTranslation('cancel')"
        @ok-button-click="requestTarif"
        @cancelButtonClick="resetTarifModal"
        @input="resetTarifModal"
    >
      <form class="form fh-form">
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('tarif') }} <span v-if="app.provider" >{{ app.provider.currency }}</span><span
              class="required">*</span></label>
          <input type="number" step="0.1" class="form-control" v-model.number="tarifForm.tarif" :class="{'is-invalid':$v.tarifForm.tarif.$error}">
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('comment') }} <span
              class="required">*</span></label>
          <textarea class="form-control" :class="{'is-invalid':$v.tarifForm.comment.$error}" cols="10" rows="5" placeholder=" Write your comment" v-model.trim="tarifForm.comment">

          </textarea>
        </div>
      </form>
    </PortalModal>
  </div>
</template>

<script>
import tarifMixin from "@/views/portal/mixins/tarifRequest";
import PortalModal from "@/views/portal/components/PortalModal";
import required from "vuelidate/lib/validators/required";

export default {
  name: "InvoiceTarifComponent",
  components: { PortalModal },
  mixins: [ tarifMixin ],
  props: {
    app: {
      type: Object,
      default: () => {
        return {
          number: null
        }
      }
    }
  },
  data() {
    return {
      invoiceRequestMsg: 'requestInvoice',
      tarifRequestMsg: 'requestTarif',
    };
  },
  created() {

    switch ( this.app.request_status ) {
      case 'TARIF':
        this.tarifRequestMsg = 'tarifRequested';
        break;
      case 'TARIF_SENT':
        this.tarifRequestMsg = 'requestTarif';
        break;
      case 'INVOICE':
        this.invoiceRequestMsg = 'invoiceRequested';
        break;
      case 'INVOICE_SENT':
        this.invoiceRequestMsg = 'requestInvoice';
        break;

      default:
        this.tarifRequestMsg = 'requestTarif';
        this.invoiceRequestMsg = 'requestInvoice';
        break;
    }
  },
  methods: {
    requestInvoice() {
      this.axios.post(this.$urls.portal.requestFor.url.replace(':id', this.app.number), { for: 'INVOICE' })
          .then(() => {
            this.invoiceRequestMsg = 'invoiceRequested';
            this.$store.dispatch('portal/setCantRequestTarif');
            this.$store.dispatch('portal/setCantRequestInvoice').then(() => {
              Swal.fire({
                icon: 'success',
                title: this.getTranslation('Invoice request is sent, invoice will be sent to your email'),
              });
            })
          }).catch(() => {
      });

    },
    requestTarif() {

      this.$v.tarifForm.$touch();

      if ( this.$v.tarifForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      let data = { for: 'TARIF', comment: this.tarifForm.comment, tarif: this.tarifForm.tarif };

      this.axios.post(this.$urls.portal.requestFor.url.replace(':id', this.app.number), data)
          .then(() => {
            this.tarifRequestMsg = 'tarifRequested';
            this.resetTarifModal();
            this.$store.dispatch('portal/setCantRequestTarif').then(() => {
              this.$store.dispatch('portal/setCantRequestInvoice');
              Swal.fire({
                icon: 'success',
                title: this.getTranslation('Tarif request is sent, information will be sent to your email'),
              });
            })
          }).catch(() => {
      });

    }
  },
  validations: {
    tarifForm: {
      tarif: {
        required
      },
      comment: {
        required
      }
    },
  }
}
</script>

<style scoped>

</style>